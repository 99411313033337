import { render, staticRenderFns } from "./Fnake.vue?vue&type=template&id=89b06be8"
import script from "./Fnake.vue?vue&type=script&lang=js"
export * from "./Fnake.vue?vue&type=script&lang=js"
import style0 from "./Fnake.vue?vue&type=style&index=0&id=89b06be8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports